import React from 'react';

const Header = () => {
  return (
    <article className="header">
      <h1>Derivación para encontrar profundidad óptima en recipientes</h1>
      <h2>Por: Diana Carolina Fernándes</h2>
    </article>
  );
};

export default Header;