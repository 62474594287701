import React from 'react';
import Alert from '../components/Alert/Alert';
import DeepContext from '../components/context/DeepContext';
import Form from '../components/Form/Form';
import Header from '../components/Header/Header';
import Introduction from '../components/Introduction/Introduction';
import Result from '../components/Result/Result';


const Home = () => {
  const [deep, setDeep] = React.useState({
    point: 0,
    volume: 0
  });
  const value = [deep, setDeep];
  return (
    <DeepContext.Provider value={value}>
      <section className="Home">
        <div className="home--container">
          <Header />
          <Introduction />
          <Form />
          {
            (deep.volume <= 0 || deep.volume === undefined || isNaN(deep.volume)) ? (
              <Alert />
            ) : (
              <Result />
            )
          }
        </div>
      </section>
    </DeepContext.Provider>
  );
};

export default Home;