import React from 'react';

const Introduction = () => {
  return (
    <article className="introduction">
      <div className="info">
        <p>
          Para encontrar profundidad óptima del recipiente, es necesario saber la base y altura de la pieza cuadrada 
          o rectangular que se requiere cortar.
        </p>
      </div>
      <div className="instructions">
        Por favor llene los datos requeridos por el formulario para procesar la información
      </div>
    </article>
  );
};

export default Introduction;