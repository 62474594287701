import React, { useEffect } from 'react';
import useDeep from '../hooks/useDeep';

const Form = () => {

  const [squareForm, setSquareForm] = React.useState({
    height: 0,
    width: 0,
  });

  const [, setDeep] = useDeep();

  const handleChange = (e: any) => {
    setSquareForm({
      ...squareForm, 
      [e.target.name]: Number(e.target.value)
    });
    
  }

  const checkResults = (val1: any, val2: any) => {
    if ( val1.volume > val2.volume ) {
      return val1;
    } else {
      return val2;
    }
  }

  const generalAdd = () => {
    const X = squareForm.width;
    const Y = squareForm.height;
    const ANS = ((4*X + 4*Y) + Math.sqrt(Math.pow((-4*X - 4*Y), 2) - (48*X*Y))) / 24;
    return ANS;
  }

  const generalMinus = () => {
    const X = squareForm.width;
    const Y = squareForm.height;
    const ANS = ((4*X + 4*Y) - Math.sqrt(Math.pow((-4*X - 4*Y), 2) - (48*X*Y))) / 24;
    return ANS;
  }
              
  const pointResult = (value: number) => {
    const X = squareForm.width;
    const Y = squareForm.height;
    const Z = value;

    const result = (4*Math.pow(Z,3) - 2*X*Math.pow(Z,2) - 2*Y*Math.pow(Z,2) + X*Y*Z)
    return {
      point: value.toFixed(2),
      volume: result.toFixed(2)
    };
  }

  useEffect(() => {
    const point1 = pointResult(generalAdd());
    const point2 = pointResult(generalMinus());

    setDeep(checkResults(point1, point2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDeep, squareForm])

  console.log(squareForm);
  return (
    <article className="form">
      <div className="input">
        <div className="formLabel"><span>Altura en cm: </span></div>
        <input 
          type="number" 
          id="height" 
          name="height" 
          min={0} 
          value={squareForm.height} 
          onChange={handleChange} 
        />
      </div>
      <div className="input">
        <div className="formLabel"><span>Anchura en cm: </span></div>
        <input 
          type="number" 
          id="width" 
          name="width" 
          min={0} 
          value={squareForm.width} 
          onChange={handleChange}
        />
      </div>
    </article>
  );
};

export default Form;