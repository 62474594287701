import React from 'react';

const Alert = () => {
  return (
    <article className="alert">
      <div className="alert--container">
        <p>Por favor, ingrese los datos necesarios para poder calcular el corte necesario para optimizar el volúmen de la caja.</p>
      </div>
    </article>
  );
};

export default Alert;