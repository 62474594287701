import React from 'react';
import useDeep from '../hooks/useDeep';

const Result = () => {

  const [deep] = useDeep();
  return (
    <article className="result">
      <div className="result--title">
        <p>El recorte de profundidad óptimo debe medir: <span>{deep?.point}</span> cm.</p>
      </div>
      <div className="result--explaination">
        <p>De usar dicha medida se obtendría un volúmen de: <span>{deep?.volume}</span> centímetros cúbicos.</p>
      </div>
    </article>
  );
};

export default Result;