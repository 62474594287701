import React from 'react';
import DeepContext from '../context/DeepContext';

const useDeep = () => {
  const context = React.useContext(DeepContext);
  if (!context) {
    throw new Error(`useCount must be rendered within the CountProvider`)
  }
  const [deep, setDeep] = context;
  const value = [deep, setDeep];
  return value;
}

export default useDeep;